/*------------------------------------------------------------------------
# author    Eric Worman, News Media Corporation
# copyright Copyright  2015. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website   Newport Chamber
# Path: /resources/assets/less/site.less
-------------------------------------------------------------------------*/
/* Import 3L mixins and apply reset.css
********************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* COLORS
********************************************/
/*
	Black: #1f1f1f / 31/31/31
*/
/* 117/117/117 */
/* 247/244/203 */
/* 242/0/0 */
/* 238/238/238 */
/* 1/156/181 */
/* 75/148/206 */
/* 59/120/69 */
.dropShadow {
  -webkit-box-shadow: 4px 4px 5px 0 #757575;
  -moz-box-shadow: 4px 4px 5px 0 #757575;
  box-shadow: 4px 4px 5px 0 #757575;
}
/* COMMON STYLES
********************************************/
body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: #333333;
  margin: auto;
  overflow-x: hidden;
}
strong {
  font-weight: bold;
}
.pageWidth {
  max-width: 1200px;
  width: 95%;
  margin: auto;
  position: relative;
}
.title {
  font-weight: bold;
  font-size: 2.4em;
  letter-spacing: -0.5px;
}
.pageTitle {
  color: #eeeeee;
  font-size: 2.2em;
  background-color: #757575;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.panelTitle {
  margin-bottom: 20px;
  color: #757575;
  font-size: 1.8em;
}
.logo {
  display: block;
  margin: auto;
}
.dashboard {
  padding-bottom: 75px;
}
.baseForm {
  padding: 25px;
  border-right: 1px solid #d7d7d7;
}
.baseForm .form-group label {
  padding: 5px 0;
}
.baseForm .btn {
  padding: 5px 10px;
  min-width: 100px;
  margin: 0 5px;
}
.selectLabel {
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.inlineLabel {
  text-align: right;
}
.fieldErrorText {
  font-size: 0.8em;
  font-weight: bold;
  color: red;
  padding-top: 10px;
  text-align: left;
  clear: both;
}
.fieldError label {
  color: red;
}
.fieldError .form-control {
  background-color: #F7F4CB;
}
.file-input-name {
  display: inline;
  margin: 5px 0 0 0;
  font-size: 0.8em;
}
.alert {
  width: 100%;
  margin: 20px auto;
}
.curRecordsContainer {
  margin-top: 25px;
}
.curRecordsTitle {
  padding-bottom: 10px;
  font-size: 1.4em;
  color: #757575;
}
.curRecord {
  margin-bottom: 10px;
}
.curRecord a {
  color: #333333;
  font-weight: bold;
}
.curRecord a:hover {
  color: #757575;
  text-decoration: none;
}
.disclaimer {
  color: #757575;
  font-size: 0.7em;
  margin-top: 5px;
}
.switchToggleContainer {
  position: relative;
  top: 9px;
}
.homeless > a {
  color: #F20000;
}
.filterBlock {
  margin-bottom: 15px;
}
/* LOGIN FORM
********************************************/
.loginForm {
  width: 350px;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid #ebebeb;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background-color: white;
}
.loginForm .title {
  text-align: center;
  padding: 20px 0;
}
.loginForm .logo {
  width: 100%;
}
.loginBackground {
  background-color: #757575;
}
/* DASHBOARD HEADER
********************************************/
.dashboardHeader {
  margin: 20px 0 30px 0;
}
.dashboardHeader .logo {
  width: 13%;
  float: left;
}
.dashboardHeader .title {
  margin-bottom: 15px;
  font-size: 1.8em;
}
.dashboardHeader .menuPanel {
  margin: 15px 0 0 0;
  width: 85%;
}
.dashboardHeader .navbar-brand {
  font-weight: 400;
  font-size: 1.0em;
}
.dashboardHeader .navbar-nav > li > a,
.dashboardHeader .navbar-nav > li > div > a {
  padding: 5px 10px;
  margin: 9px 10px 0 0;
}
.dashboardHeader .navbar-default .navbar-nav > li > a:focus,
.dashboardHeader .navbar-default .navbar-nav > li > a:hover {
  background-color: #e0e0e0;
}
.frontifyLink {
  color: white;
  font-size: 22px;
  line-height: 32px;
}
.frontifyLink:hover {
  color: white;
  text-decoration: underline;
}
.dashboardNavContainer {
  width: 90%;
  position: absolute;
  right: 0;
}
/* DASHBOARD FOOTER
********************************************/
.dashboardFooter {
  background-color: #757575;
  padding: 10px 0 5px 0;
  color: white;
  font-style: normal;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}
.copyright {
  color: white;
  line-height: 30px;
  font-size: 0.8em;
  margin-right: 10px;
}
.copyright:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.siteName {
  margin: 8px 20px 0 0;
}
/* SELECT SITE PAGE
********************************************/
.selectSitePage .alert {
  max-width: 580px;
  width: 95%;
}
.selectSitePage .logo {
  height: 150px;
  margin-top: 20px;
}
.selectSitePage .pageTitle {
  margin: 50px auto;
  font-size: 3.3em;
  text-align: center;
}
.selectSitePage .sitesContainer {
  text-align: center;
}
.selectSitePage .sitesContainer > li {
  border: 2px dashed #d7d7d7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  color: #d7d7d7;
  position: relative;
  padding: 10px;
  width: 180px;
  height: 200px;
  display: inline-block;
  margin: 20px;
}
.selectSitePage .sitesContainer > li:hover {
  background-color: #333333;
  cursor: pointer;
}
.selectSitePage .sitesContainer .fa {
  color: #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  text-align: center;
  font-size: 5.5em;
}
.selectSitePage .sitesContainer .siteLogo {
  max-height: 110px;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  text-align: center;
}
.selectSitePage .sitesContainer .siteLogo > img {
  width: auto;
  max-height: 110px;
}
.selectSitePage .sitesContainer .siteName {
  font-size: 1.3em;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.newSiteForm {
  width: 95%;
  max-width: 500px;
  margin: 10px auto 100px;
  -webkit-box-shadow: 4px 4px 5px 0 #757575;
  -moz-box-shadow: 4px 4px 5px 0 #757575;
  box-shadow: 4px 4px 5px 0 #757575;
}
.newSiteForm .inputButtons {
  margin-top: 40px;
}
.newSiteForm .selectLabel {
  margin-right: 0;
}
.pageSectionsContainer {
  margin-top: 30px;
}
.sectionPreviewContainer .page {
  border: 1px solid #d7d7d7;
  width: 100%;
  height: 600px;
  margin: auto;
}
.sectionPreviewContainer .borderTop {
  border-top: 1px solid #d7d7d7;
}
.sectionPreviewContainer .borderBottom {
  border-bottom: 1px solid #d7d7d7;
}
.sectionPreviewContainer .borderRight {
  border-right: 1px solid #d7d7d7;
}
.sectionPreviewContainer .borderLeft {
  border-left: 1px solid #d7d7d7;
}
.sectionPreviewContainer .sectionName {
  border: none;
  color: #757575;
  display: block;
  padding: 3px 5px;
  font-size: 0.8em;
  width: 100%;
  text-align: center;
  height: 30px;
  margin-top: -15px;
  top: 50%;
  position: absolute;
  width: 150px;
  left: 50%;
  margin-left: -75px;
  background-color: transparent;
}
.sectionWidthContainer > div {
  margin: 10px 0 50px 0;
}
.sectionDetails {
  text-align: center;
}
.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  bottom: -32px;
  top: 32px;
  border: none;
  font-size: 0.7em;
}
.activeSection {
  background-color: #ADD9FF;
}
.noSectionsDefined {
  color: #757575;
  text-align: center;
  margin-top: 48%;
}
.pageForm .panelTitle {
  margin-bottom: 0px;
}
.pageForm .disclaimer {
  margin-bottom: 20px;
}
.pageManagerPage .unpublished a {
  color: #478ec7;
}
.dashboardNavContainer .btn {
  margin: 10px 10px 0 0;
}
.sectionDivisions {
  width: 98%;
  margin: auto;
}
.sectionDivisions img {
  width: 100%;
}
.sectionDivisions .section {
  margin: 5px 0;
}
.pageSectionPreview {
  position: relative;
}
.pageSectionPreview .tooltip-inner {
  width: auto;
}
.pageSectionPreview .sectionControls {
  position: absolute;
  left: -20px;
  padding-top: 10px;
}
.pageSectionPreview .sectionControls a {
  display: block;
  color: #757575;
  font-weight: 300;
  margin-bottom: 10px;
}
.pageSectionPreview .contentSection {
  border: 1px solid #d7d7d7;
  height: 150px;
}
.inlineSectionSelector {
  background-color: white;
}
.inlineSectionSelector li {
  width: 150px;
}
.inlineSectionSelector img {
  width: 100%;
}
/* EDIT SITE
********************************************/
.siteStyles li {
  margin: 5px 0;
}
.siteStyles .input-group-addon:first-of-type {
  border-left: none;
}
.siteStyles .input-group-addon:hover {
  cursor: pointer;
}
.siteStyles .editStyleInput {
  font-weight: bold;
}
.siteStyles .editStyleContainer {
  display: none;
}
.siteStyles .noRecordsFound {
  margin-bottom: 10px;
}
.siteSettingsPage .inputButtons {
  width: 90%;
  margin: auto;
}
.siteSettingsPage .btn {
  margin-right: 10px;
}
/* CONTENT
********************************************/
.imagesContainer {
  width: 500px;
}
.imagesContainer .image {
  float: left;
  position: relative;
  text-align: center;
  width: 31%;
  padding: 5px;
  margin: 10px 1%;
  height: 140px;
  border: 1px solid #ebebeb;
}
.imagesContainer .image img {
  max-width: 100%;
  max-height: 100px;
}
.imagesContainer .btn {
  position: absolute;
  bottom: 5px;
  width: 93%;
}
.linkButtonGroup {
  background-color: #eeeeee;
  padding: 10px;
  margin-bottom: 10px;
}
.contentForm.baseForm .btn {
  padding: 6px 10px;
}
.contentForm .input-group > input {
  border-right: 0px;
}
.selectPagesContainer {
  padding: 25px 40px;
}
.currentPagesContainer {
  text-align: center;
}
.currentPagesContainer > li {
  border: 2px dashed #d7d7d7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
  color: #757575;
  position: relative;
  padding: 10px;
  width: 180px;
  height: 180px;
  display: inline-block;
  margin: 20px;
}
.currentPagesContainer > li:hover {
  background-color: #333333;
  cursor: pointer;
}
.currentPagesContainer > li.active {
  background-color: #333333;
}
.currentPagesContainer .fa {
  color: #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  text-align: center;
  font-size: 5.5em;
}
.currentPagesContainer .sectionName {
  font-size: 1.3em;
  line-height: 150px;
  text-align: center;
  margin: auto;
}
.currentPagesContainer li.noPagesDefined {
  border: none;
  width: 100%;
  text-align: left;
  margin: 0;
}
.currentPagesContainer li.noPagesDefined:hover {
  background-color: white;
  cursor: default;
}
#headlineGroup .input-group-btn:last-child > .btn,
#headlineGroup .input-group-btn:last-child > .btn-group {
  z-index: 10;
}
/* DIRECTORY
********************************************/
.listing {
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.listing:hover {
  cursor: pointer;
  background-color: #757575;
  color: white;
}
