/*------------------------------------------------------------------------
# author    Eric Worman, News Media Corporation
# copyright Copyright  2015. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website   Newport Chamber
# Path: /resources/assets/less/site.less
-------------------------------------------------------------------------*/

/* Import 3L mixins and apply reset.css
********************************************/
@import '3L/3L';

.reset();

/* COLORS
********************************************/
/*
	Black: #1f1f1f / 31/31/31
*/

//Standard styles
@black: #333333;
@gray: #ebebeb;
@borderColor: #d7d7d7;
@darkGray: #757575; /* 117/117/117 */
@yellow: #F7F4CB; /* 247/244/203 */
@red: #F20000; /* 242/0/0 */

@ltGray: #eeeeee; /* 238/238/238 */
@teal: #019cb5; /* 1/156/181 */
@ltTeal: lightness(@teal) + 20;
@darkTeal: lightness(@teal) - 20;
@blue: #478ec7; /* 75/148/206 */
@ltBlue: #ADD9FF;
@darkBlue: #3b78a9; /* 59/120/69 */

@bodyFont: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

@pageWidth: 90%;

.dropShadow{
  .box-shadow(4px 4px 5px 0 @darkGray);
}

/* COMMON STYLES
********************************************/
body {
  font-family: @bodyFont; font-weight: 300; color:@black; margin:auto; overflow-x:hidden;
}

strong { font-weight:bold; }

.pageWidth { max-width:1200px; width:95%; margin:auto; position:relative; }
.title { font-weight:bold; font-size:2.4em; letter-spacing:-0.5px; }
.pageTitle { color:@ltGray; font-size:2.2em; background-color:@darkGray; .border-radius(5px); padding:10px 20px; margin-bottom:10px; }
.panelTitle { margin-bottom:20px; color:@darkGray; font-size:1.8em; }
.logo { display:block; margin:auto; }

.dashboard { padding-bottom:75px; }

.baseForm{
  padding:25px; border-right:1px solid @borderColor;
  .form-group label { padding:5px 0; }
  .btn { padding:5px 10px; min-width:100px; margin:0 5px; }
}

.selectLabel { position:relative; top:3px; margin-right:5px; }
.inlineLabel { text-align:right; }

.fieldErrorText { font-size:0.8em; font-weight:bold; color:red; padding-top:10px; text-align:left; clear:both; }
.fieldError label { color:red; }
.fieldError .form-control { background-color: @yellow; }
.file-input-name { display:inline; margin:5px 0 0 0; font-size:0.8em; }

.alert { width:100%; margin:20px auto; }

.curRecordsContainer { margin-top:25px; }
.curRecordsTitle { padding-bottom:10px; font-size:1.4em; color:@darkGray; }
.curRecord { margin-bottom:10px; }
.curRecord a { color:@black; font-weight:bold; }
.curRecord a:hover { color:@darkGray; text-decoration:none; }
.disclaimer { color:@darkGray; font-size:0.7em; margin-top:5px; }

.switchToggleContainer { position:relative; top:9px; }

.homeless > a{ color:@red; }

.filterBlock { margin-bottom:15px; }

/* LOGIN FORM
********************************************/
.loginForm{
  width:350px; margin:30px auto; padding:20px; border:2px solid @gray; .border-radius(25px); background-color:white;
  .title { text-align: center; padding:20px 0; }
  .logo { width:100%;  }
}
.loginBackground { background-color:@darkGray; }


/* DASHBOARD HEADER
********************************************/
.dashboardHeader {
  margin:20px 0 30px 0;
  .logo { width:13%; float:left; }
  .title { margin-bottom: 15px; font-size:1.8em; }
  .menuPanel { margin:15px 0 0 0; width:85%; }
  .navbar-brand { font-weight:400; font-size:1.0em; }
  .navbar-nav > li > a, .navbar-nav > li > div > a { padding:5px 10px; margin:9px 10px 0 0; }
  .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover { background-color:#e0e0e0; }
}

.frontifyLink { color:white; font-size:22px; line-height: 32px; }
.frontifyLink:hover { color:white; text-decoration:underline; }
.dashboardNavContainer { width: 90%; position:absolute; right:0; }


/* DASHBOARD FOOTER
********************************************/
.dashboardFooter {
  background-color:@darkGray; padding:10px 0 5px 0; color:white; font-style:normal; text-align:center; position:fixed; bottom:0; left:0; right:0; z-index:500;
}
.copyright { color:white; line-height:30px; font-size:0.8em; margin-right:10px;  }
.copyright:hover { color:white; text-decoration:underline; cursor:pointer; }
.siteName { margin:8px 20px 0 0; }


/* SELECT SITE PAGE
********************************************/
.selectSitePage{
  .alert { max-width:580px; width:95%; }
  .logo { height:150px; margin-top:20px; }
  .pageTitle { margin:50px auto; font-size:3.3em; text-align:center; }

  .sitesContainer { text-align:center; }
  .sitesContainer > li { border:2px dashed @borderColor; .border-radius(3px); overflow:hidden; color:@borderColor; position:relative; padding:10px; width:180px; height:200px; display:inline-block; margin:20px; }
  .sitesContainer > li:hover { background-color:@black; cursor:pointer; }
  .sitesContainer .fa { color:@borderColor; position:absolute; left:0; right:0; top:40px; text-align:center; font-size:5.5em; }
  .sitesContainer .siteLogo { max-height:110px; width:auto; position:absolute; left:0; right:0; top:10px; text-align:center; }
  .sitesContainer .siteLogo > img { width:auto; max-height:110px; }
  .sitesContainer .siteName { font-size:1.3em; position:absolute; bottom:10px; left:0; right:0; text-align:center; margin:auto; }
}

.newSiteForm {
  width:95%; max-width:500px; margin:10px auto 100px; .dropShadow;
  .inputButtons { margin-top:40px; }

  .selectLabel { margin-right:0; }

}

.pageSectionsContainer { margin-top:30px; }

.sectionPreviewContainer {
  .page { border:1px solid @borderColor; width:100%; height:600px; margin:auto; }
  .borderTop { border-top:1px solid @borderColor; }
  .borderBottom { border-bottom:1px solid @borderColor; }
  .borderRight { border-right:1px solid @borderColor; }
  .borderLeft { border-left: 1px solid @borderColor; }

  .sectionName {
    border:none; color:@darkGray;
    display:block; padding:3px 5px;
    font-size:0.8em; width:100%; text-align:center;
    height:30px; margin-top:-15px; top:50%; position:absolute;
    width:150px; left:50%; margin-left:-75px;
    background-color:transparent;
  }
}

.sectionWidthContainer > div { margin:10px 0 50px 0;  }
.sectionDetails { text-align:center; }
.noUi-horizontal .noUi-handle-lower .noUi-tooltip { bottom:-32px; top:32px; border:none; font-size:0.7em; }
.activeSection { background-color:@ltBlue; }
.noSectionsDefined { color:@darkGray; text-align:center; margin-top:48%; }

.pageForm {
  .panelTitle { margin-bottom:0px; }
  .disclaimer { margin-bottom:20px;  }
}

.pageManagerPage {
  .unpublished a { color:@blue; }
}

.dashboardNavContainer .btn { margin:10px 10px 0 0; }

.sectionDivisions {
  width:98%; margin:auto;
  img { width:100%; }
  .section { margin:5px 0; }
}

.pageSectionPreview {
  position:relative;
  .tooltip-inner { width:auto; }
  .sectionControls { position:absolute; left:-20px; padding-top:10px; }
  .sectionControls a { display:block; color:@darkGray; font-weight:300; margin-bottom:10px; }
  .contentSection { border:1px solid @borderColor; height:150px; }
}


.inlineSectionSelector {
  background-color:white;
  li { width:150px; }
  img { width:100%; }
}




/* EDIT SITE
********************************************/

.siteStyles {

  li { margin:5px 0; }
  .input-group-addon:first-of-type { border-left:none; }
  .input-group-addon:hover { cursor:pointer; }
  .editStyleInput { font-weight:bold; }
  .editStyleContainer { display:none; }
  .noRecordsFound { margin-bottom:10px; }
}

.siteSettingsPage {
  .inputButtons { width:90%; margin:auto; }
  .btn { margin-right:10px; }
}



/* CONTENT
********************************************/
.imagesContainer {
  width:500px;

  .image { float:left; position:relative; text-align:center; width:31%; padding:5px; margin:10px 1%; height:140px; border:1px solid @gray; }
  .image img { max-width:100%; max-height:100px; }
  .btn { position:absolute; bottom:5px; width:93%; }
}

.linkButtonGroup { background-color:@ltGray; padding:10px; margin-bottom:10px; }
.contentForm.baseForm .btn { padding:6px 10px; }

.contentForm .input-group > input { border-right:0px; }

.selectPagesContainer { padding:25px 40px; }
.currentPagesContainer { text-align:center; }
.currentPagesContainer > li { border:2px dashed @borderColor; .border-radius(3px); overflow:hidden; color:@darkGray; position:relative; padding:10px; width:180px; height:180px; display:inline-block; margin:20px; }
.currentPagesContainer > li:hover { background-color:@black; cursor:pointer; }
.currentPagesContainer > li.active { background-color:@black; }
.currentPagesContainer .fa { color:@borderColor; position:absolute; left:0; right:0; top:40px; text-align:center; font-size:5.5em; }
.currentPagesContainer .sectionName { font-size:1.3em; line-height:150px; text-align:center; margin:auto; }
.currentPagesContainer li.noPagesDefined { border:none; width:100%; text-align:left; margin:0; }
.currentPagesContainer li.noPagesDefined:hover { background-color:white; cursor:default; }

#headlineGroup .input-group-btn:last-child > .btn, #headlineGroup .input-group-btn:last-child > .btn-group { z-index:10; }


/* DIRECTORY
********************************************/

.listing { padding:5px; .border-radius(5px); }
.listing:hover { cursor:pointer; background-color:@darkGray; color:white; }